import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';

interface ItemsResponseObj { }
interface ItemsResponse { }

@Injectable({
  providedIn: 'root'
})

export class SupervisordashboardService {

  todaydate;

  constructor(private httpClient: HttpClient) { }
  
  queues_data(queue): Observable<ItemsResponse> {
    return this.httpClient.get<ItemsResponse>('https://ea1q4khyac.execute-api.us-east-1.amazonaws.com/default/getMetrics?queueID=' + queue + '');
  }

  queues_data_newfields(queue): Observable<ItemsResponse> {
    return this.httpClient.get<ItemsResponse>('https://stellarit-ai.com/dashboardv/agentkinesis/queue-table?casQueueName=' + queue + '');
  }

  agents_data(qeuevalue): Observable<ItemsResponse> {
   this.todaydate = new Date();
    var dd = String(this.todaydate.getDate()).padStart(2, '0');
    var mm = String(this.todaydate.getMonth() + 1).padStart(2, '0'); //January is 0!
    var yyyy = this.todaydate.getFullYear();

    this.todaydate = yyyy + '-' + mm + '-' + dd;
  return this.httpClient.get<ItemsResponse>(environment.apiUrl + 'agentkinesis/agent-tabledata?state=1&casQueueName=' + qeuevalue + '');    

  }  



}
